<template>
        <div class="textdiv">
         <div v-for="(textitem,index) of textArr" :key="index" class="item" :class="{activeitem:index===selectIndex}" @click="textclick(index)">
  <p> {{textitem}}</p>          
         </div>
        </div>
</template>
<script>
export default {
    name:'TextClick',
    data(){
        return {
            selectIndex:0
        }
    },
    props:{
        textArr:{
            type:Array,
            default:function(){
                return []
            }
        }
    },
    methods:{
        textclick(index){
            this.selectIndex = index
            this.$emit('clickMyHobby',index)
        }
    }
}
</script>
<style>
.textdiv{
    display: flex;
    /* position: sticky;
    top: 0px; */
    background-color: #ffffff;
}
.item{
    flex: 1;
    text-align: center;
    font-size: 30px;
    line-height: 120px;
    padding-bottom: 20px;
}
.activeitem{
    color: #268CE4;
}
.activeitem p{
 border-bottom: #268CE4 solid 2px;
}

</style>