<template>
    <div class="main">
      <div class="backG">
        <span class="loginOut" @click="loginOut">退出</span>
      </div>
      <div class="personDiv">
        <img src="~assets/image/main/RightTopImg.png" alt="" class="rightTop">
        <div class="headDiv">
          <img src="~assets/image/main/headImg.png" alt="" class="headImg">
          <div>
            <p class="name">{{perMes.username}}</p>
            <p class="major">{{perMes.job}}</p>
          </div>
        </div>
        <div class="messageDiv">
          <img src="~assets/image/main/num.png" alt="" class="numImg">
          <span class="messageTitle">员工编号:</span>
          <span class="messageValue">{{perMes.account}}</span>
        </div>
        <div class="messageDiv">
          <img src="~assets/image/main/manager.png" alt="" class="managerImg">
          <span class="messageTitle">所属支行:</span>
          <span class="messageValue">{{perMes.orgName}}</span>
        </div>
        <div class="messageDiv">
          <img src="~assets/image/main/accound.png" alt="" class="accountImg">
          <span class="messageTitle">登录账号:</span>
          <span class="messageValue">{{perMes.account}}</span>
        </div>
      </div>

      <div class="selectStatus">
          <TextClick :textArr="textArr"  @clickMyHobby="clickMyHobby"></TextClick>
      </div>
    

      <div class="staffListDiv">
          <!-- <StaffList :staffArr='staffArr'></StaffList> -->
           <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list
                    v-model="loading"  
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                >
                    
                    <StaffList :staffArr='staffArr'>
                    </StaffList>
                    
                </van-list>
               </van-pull-refresh>
      </div>
    
    
        
  </div>
</template>
<script>
import TextClick from 'components/textClick/TextClick.vue'
import StaffList from 'components/staffList/StaffList.vue'
import {countTask,getList,getPersonMessage} from '../../network/normal'
export default {
  name: 'Main',
  data(){
    return{
      textArr:[],
      perMes:{},
      staffArr:[],
       loading: false,
            finished: false,
            pageNum:1,
            pageSize:2,
            total:0,
            refreshing:false,
            state:0
    }
  },
  created(){
    countTask().then((res)=>{
        if(res.success){
          res.data.forEach((ele,index) => {
            if(index === 0 ){
              if(ele.remark === '待点评'){
                    this.state = 0
              }else if(ele.remark === '待签核'){
                    this.state = 1
              }else if(ele.remark === '考核完成'){
                    this.state = 2
              }
              else if(ele.remark === '待自评'){
                    this.state = 3
              }
            }
            this.textArr.push(ele.remark)
          });
          
         
        }
    });
    
    getPersonMessage().then((res)=>{
     
      if(res.success){
          this.perMes = res.data
      }else{
        this.$toast.fail(res.msg);
      }
    })
    
  },
  components:{
    TextClick,
    StaffList
  },
  mounted(){
    document.querySelector('body').setAttribute('style','background-color:#F5F5F5')
  },
  beforeDestroy(){
    document.querySelector('body').removeAttribute('style')
  },
  methods:{
    clickMyHobby(index){
        let text = this.textArr[index];
        if(text == '待点评'){
          this.state = 0
        }else if(text == '待签核'){
          this.state = 1
        }else if(text =='考核完成'){
            this.state = 2
        }
        else if(text =='待自评'){
            this.state = 3
        }
        this.staffArr= [];
        this.pageNum = 1;
       
            this.finished = false;
            this.loading = true;
        this.onLoad();
    },
    loginOut(){
      localStorage.removeItem('token');
      this.$router.replace('./login')
    },
 getDataList(state){
                getList(state,'','',this.pageNum,this.pageSize).then((res)=>{
                        if(res.success){    
                            if(res.data.isLastPage){

                                this.finished = true
                            } 
                            this.total = res.total
                            
                            for (const iterator of res.data.list) {
                                this.staffArr.push(iterator)
                            }
                            this.loading = false;
                            this.refreshing = false
                            if (this.staffArr.length >= res.total) {
                                this.finished = true;		// 结束加载状态
                            }
                            this.pageNum++;				  
                        }else{
                            this.$toast.fail(res.msg)
                        }
                })
        },
        onLoad() {   
            this.getDataList(this.state);			
            
        },
        onRefresh(){
            
            this.staffArr = [];
            this.finished = false;
            this.loading = true;
            this.pageNum = 1;
            this.onLoad();
        }
  }
}

</script>
<style scoped>
.main{
  width: 100%;
  height: 100%;
}
  .backG{
    width: 750px;
    height: 362px;
    background: linear-gradient(-55deg, #2998F8, #08A3FE);
    position: relative;
  }
  .loginOut{
    position: absolute;
    right: 3%;
    top: 100px;
    color: #ffffff;
  }
  .personDiv{
    margin: -178px 3%;
    width: 702px;
    height: 434px;
    background: #FFFFFF;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
  }
  .rightTop{
    position: absolute;
    right: 0;
    top: 0;
    width: 64px;
  }
  .headDiv{
    display: flex;
    margin-top: 40px;
    margin-left: 53px;
    align-items: center;
  }
  .headImg{
      width: 100px;
      margin-right: 26px;
  }
  
  .name{
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  .major{
    margin-top: 18px;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
  .numImg{
    width: 26px;
    margin-right: 31px;
    
  }
  .managerImg{
    width: 33px;
    margin-right: 28px;
  }
  .accountImg{
    width: 34px;
    margin-right: 27px;
  }
  .messageDiv{
    margin: 50px 0px 50px 91px;
  }
  .messageTitle{
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
  .messageValue{
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-left: 20px;
  }
  .selectStatus{
    margin-top: 210px;
  }
  .staffListDiv{
    background-color: #ffffff;
  }
  .van-pull-refresh{
    /* height: calc(100vh - 832px); */
    height: 700px;
    overflow: scroll;
  }
</style>